$primary-color: #ff206e;
$primary-hover-color-light: rgba($primary-color, 0.2);
$primary-hover-color-dark: rgba($primary-color, 0.75);
$link-hover: #510d0a;
$secondary-color: #7b9ea8;
$tertiary-color: #ff1800;
$danger-color: red;
$info-color: #ffcb77;
$light-color: #ebeff2;
$background-color: #f5f6f8;
$outline-color: #dfdfdf;
$text-color: #212121;
$button-corner-radius: 4px;

body {
    font-family: "DM Sans", sans-serif;
    margin: 0;
}

* {
    box-sizing: border-box;
}

button,
input {
    font-family: unset;
    font-size: unset;
    line-height: unset;
}

hr {
    margin: unset;
}

a {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}

.react-codemirror2,
.CodeMirror {
    height: 100% !important;
}

.CodeMirror-empty {
    color: gray !important;
}
